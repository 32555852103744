import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, useToast } from "@chakra-ui/react";
import Header from "components/Header";
import CreateButton from "components/CreateButton";
import CreateModal from "./CreateModal";
import { CMSModels, CMSModel } from "./CMSModels";
import { get } from "api";
import DataTable from "./DataTable";
import { useAuthContext } from "providers/AuthProvider/AuthProvider";

//Some names are not user-friendly
const userFriendlyTitle = {
  tap: "Tapping Chart",
  "9g": "9G Steps",
};
const Model: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const { model } = useParams<{ model: string }>();
  const MODEL = CMSModels[model];

  const [modelCategories, setModelCategories] = useState<any>([]);
  const toast = useToast();

  //the state for tracking the records
  const [records, setRecords] = useState<CMSModel[]>([]);
  const { user } = useAuthContext();

  //method to fetch the data for the current model
  const fetchRecords = useCallback(async () => {
    let response = await get<Array<CMSModel>>(
      MODEL.getEndpoint,
      undefined,
      user?.accessToken
    ).catch(() => {
      toast({
        title: "Failed to load data..",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    });
    if (response && Array.isArray(response)) {
      for (const entry of response) {
        if (entry.name) {
          entry.name =
            userFriendlyTitle[entry.name as keyof typeof userFriendlyTitle] ||
            entry.name;
        }
      }
      setRecords(response);
    } else {
      toast({
        title: "Failed to load data..",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  }, [MODEL.getEndpoint, toast, user?.accessToken]);

  const getModelCategories = useCallback(async () => {
    const response = await get(
      MODEL.getCategoryEndpoint || "",
      undefined,
      user?.accessToken
    ).catch(() => {});
    if (response && Array.isArray(response)) {
      setModelCategories(response);
    } else {
      toast({
        title: "Failed to load data..",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  }, [MODEL.getCategoryEndpoint, toast, user?.accessToken]);

  useEffect(() => {
    try {
      setLoading(true);
      fetchRecords();
      if (MODEL.getCategoryEndpoint) {
        getModelCategories();
      }
    } catch {
      setLoading(false);
    } finally {
      setLoading(false);
    }
    // Specify how to clean up after this effect:
    return function cleanup() {
      setRecords([]);
    };
  }, [MODEL, fetchRecords, getModelCategories]);
  if (!MODEL) {
    return (
      <div>
        <p>No Model Found</p>
      </div>
    );
  }
  if (loading) {
    return <div>Loading...</div>;
  }
  return (
    <>
      <Box padding="10px 0">
        <Header
          title={model.includes("_") ? model.replace("_", " ") : model}
          actions={
            <>
              <CreateModal
                title={MODEL.title}
                btnProps={{ color: "success", title: "Create" }}
                actions={<CreateButton />}
                labels={MODEL.requiredFields}
                postEndpoint={MODEL.postEndpoint}
                refetchRecords={fetchRecords}
                populated={MODEL.populated}
                records={records}
              />
            </>
          }
        />
      </Box>
      <DataTable
        fields={
          MODEL.common
            ? [...MODEL.displayFields, { key: "Content" }, { key: "Delete" }]
            : [
                ...MODEL.displayFields,
                { key: "ArContent" },
                { key: "EnContent" },
                { key: "Delete" },
              ]
        }
        items={records}
        inputFields={MODEL.fields}
        putEndpoint={MODEL.putEndpoint}
        deleteEndpoint={MODEL.deleteEndpoint}
        categories={modelCategories}
        updateCategoryEndpoint={MODEL.updateCategoryEndpoint}
        refetchRecords={fetchRecords}
        populated={MODEL.populated}
        putMethod={MODEL.putMethod}
        postEditEndpoint={MODEL.postEndpoint}
        common={MODEL.common}
      />
    </>
  );
};

export default Model;

import React from "react";
import type { CMSModel } from "../CMSModels";
import type { DataTableProps } from "./DataTable.type";
import { currentLocales, Locale } from "providers/LocaleProvider/Locales";
import ModelEdit from "../ModelEdit";
import ModelDelete from "../ModelDelete";
import ModelCategory from "../ModelCategory";

//organ label map
const labelMapOrg = {
  feet: "Lower Legs",
  legs: "Upper Legs",
};

type GetSlotsProps = Omit<DataTableProps, "fields" | "items">;

export function getSlots({
  putEndpoint,
  common,
  refetchRecords,
  putMethod,
  inputFields,
  postEditEndpoint,
  populated,
  deleteEndpoint,
  categories,
  updateCategoryEndpoint,
}: GetSlotsProps) {
  let slots: {
    [key: string]: (item: CMSModel) => React.ReactNode;
  } = {};
  if (common) {
    slots = {
      Content: (item: CMSModel) => (
        <ModelEdit
          key={item.id}
          refetchRecords={refetchRecords}
          putMethod={putMethod}
          locale="en"
          item={item}
          putEndpoint={putEndpoint}
          inputFields={inputFields}
          postEditEndpoint={postEditEndpoint}
          populated={populated}
        />
      ),
      organ: (item: CMSModel) => (
        <td style={{ textTransform: "capitalize" }}>
          {labelMapOrg[item.organ as keyof typeof labelMapOrg] || item.organ}
        </td>
      ),
    };
  } else {
    currentLocales.forEach((loc: Locale) => {
      slots = {
        ...slots,
        [loc.label]: (item: CMSModel) => (
          <ModelEdit
            key={item.id}
            refetchRecords={refetchRecords}
            putMethod={putMethod}
            locale={loc.key}
            item={item}
            putEndpoint={putEndpoint}
            postEditEndpoint={postEditEndpoint}
            inputFields={inputFields}
            populated={populated}
          />
        ),
        organ: (item: CMSModel) => (
          <td style={{ textTransform: "capitalize" }}>
            {labelMapOrg[item.organ as keyof typeof labelMapOrg] || item.organ}
          </td>
        ),
      };
    });
  }
  slots = {
    ...slots,
    Delete: (item: CMSModel) => (
      <ModelDelete
        key={item.id}
        populated={populated}
        refetchRecords={refetchRecords}
        deleteEndpoint={deleteEndpoint}
        item={item}
      />
    ),
    category: (item: CMSModel) => (
      <ModelCategory
        key={item.id}
        populated={populated}
        refetchRecords={refetchRecords}
        categories={categories}
        item={item}
        deleteEndpoint={deleteEndpoint}
        updateCategoryEndpoint={updateCategoryEndpoint}
      />
    ),
  };
  return slots;
}

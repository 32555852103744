import { CMSModel } from "./CMSModels";
import React, { FormEvent, useEffect, useRef, useState } from "react";
import { Box, IconButton, Text, useToast } from "@chakra-ui/react";
import { useAuthContext } from "../../../providers/AuthProvider/AuthProvider";
import { deleteMedia, post, uploadFile } from "../../../api";
import {
  CButton,
  CInput,
  CInputCheckbox,
  CInputFile,
  CLabel,
  CTextarea,
} from "@coreui/react";
import { FaTimes } from "react-icons/fa";
import { uniq, uniqueId } from "lodash";
//A map that joins each type with its suitable component
const compMap = {
  text: CInput,
  file: CInputFile,
  checkbox: CInputCheckbox,
  date: CInput,
  textArea: CTextarea,
};
//map for input labels that is not quite good
export const labelMap: any = {
  mediaUrl: "Media File",
  imageUrl: "Image",
  videoUrl: "Video",
  url: "Audio File",
  mediaFiles: "Audio Files",
  videoTFT: "Video",
  imageTFT: "Image",
};
export default function useModelEdit({
  item,
  locale,
  putEndpoint,
  refetchRecords,
  putMethod,
}: {
  item: CMSModel;
  locale: string;
  putEndpoint: string;
  refetchRecords: () => void;
  putMethod: string | undefined;
}) {
  const [mediaFs, setMediaFs] = useState(1);

  //when a file is uploaded
  const [doneUrl, setDoneUrl] = useState<{
    name?: string;
    url?: string;
    key?: string;
  }>({ name: "", url: "" });
  const prevDoneUrl = useRef<{ name?: string; url?: string; key?: string }>({
    name: "",
    url: "",
  });

  //temp for add inputs
  const [temp, setTemp] = useState<any>({});

  const toast = useToast();
  const { user } = useAuthContext();
  const [content, setContent] = useState<any>(""); // richText the generated html
  const [text, setText] = useState<any>(""); // richText the row text

  //update the values for current item
  useEffect(() => {
    if (item) setData(item);
    setTAdded({ val: false, article: false });
    const translation = item.translations?.find(
      (translation) => translation.locale === locale
    );
    // @ts-ignore
    setContent(translation?.html || translation?.description || "");
    // @ts-ignore
    setText(translation?.text || "");
  }, [item, locale]);

  //module name
  const moduleName = putEndpoint.split("/")[0];

  //saving the data entered by user
  const [data, setData] = useState<any>({
    ...item,
    mediaFiles: item.translations?.find((t: any) => t.locale === locale)
      ?.mediaFiles,
  });

  //effect to update data when item changes
  useEffect(() => {
    setData((data: any) => {
      return {
        ...data,
        mediaFiles: item.translations?.find((t: any) => t.locale === locale)
          ?.mediaFiles,
      };
    });
  }, [item, locale]);

  // article list

  const articlesList = useRef(null);

  //type of video
  const [videoType, setType] = useState("upload");

  //track the upload progress
  const [uploading, setUploading] = useState<any>({});

  //indicates that translation is added to data
  const [TAdded, setTAdded] = useState({ article: false, val: false });

  //uploaded boolean
  const [uploaded, setUploaded] = useState(true);

  const [uploadBoolean, setUploadBoolean] = useState(false);

  //processing state
  const [processing, setProcessing] = useState(false);

  //control the visibility for the edit modal
  const [visible, setVisible] = useState(false);

  //media type
  const [mediaType, setMediaType] = useState("");

  //function for handling changes for inputs of type text
  const handleTextChange = (e: FormEvent) => {
    const target = e.target as HTMLInputElement;
    if (target.value === "video") {
      setMediaType("video");
    } else if (target.value === "audio") {
      setMediaType("media");
    }
    if (target.name.includes("mediaF")) {
      const targetKey = target.getAttribute("data-kop");
      if (data.mediaFiles) {
        setData((data: any) => {
          const dataClone = [...data.mediaFiles];
          // eslint-disable-next-line eqeqeq
          const searching = dataClone.find((m: any) => m.id == targetKey);
          var returnData;

          if (searching) {
            searching.name = target.value;

            returnData = { ...data, mediaFiles: dataClone };
          } else {
            const newMedia = {
              id: targetKey,
              name: target.value,
              musicFreeUrl: "",
              withMusicUrl: "",
            };
            returnData = {
              ...data,
              mediaFiles: [...data.mediaFiles, newMedia],
            };
          }

          return returnData;
        });
      } else {
        setData((data: any) => ({
          ...data,
          mediaFiles: [
            {
              id: targetKey,
              name: target.value,
              musicFreeUrl: "",
              withMusicUrl: "",
            },
          ],
        }));
      }
      return;
    }
    setData((data: any) => ({ ...data, [target.name]: target.value }));
  };
  const handleChange = (e: FormEvent) => {
    const target = e.target as HTMLInputElement;
    target.value === "browse" && target.name === "video"
      ? setType("browse")
      : setType("upload");
  };
  //function for handling changes for inputs of type files
  const handleMediaChange = async (e: FormEvent) => {
    const target = e.target as HTMLInputElement;
    if (uploading[target.name] && !target.name.includes("mediaF")) return;
    if (!target.name.includes("mediaF") && target.files) {
      setUploading((prevUploading: any) => {
        return { ...prevUploading, [target.name]: true };
      });
    }
    if (
      target.name.includes("mediaF") &&
      target.files &&
      target.files.length === 1
    ) {
      const targetKey = target.getAttribute("data-kop");
      if (uploading[`mediaF-${targetKey}`]) return;
      setUploadBoolean(true);
      setProcessing(true);
      setUploading((prevUploading: any) => {
        return { ...prevUploading, [target.name]: true };
      });

      const url = await uploadFile(target.files[0], user?.accessToken);
      //delete old url -> field, module name
      setUploadBoolean(false);
      setUploading((prevUploading: any) => {
        return { ...prevUploading, [target.name]: false };
      });
      setUploaded(true);
      setProcessing(false);
      setDoneUrl({ name: target.name, url, key: targetKey || undefined });
      const path = deleteRequest(moduleName, target.name);
      const response: any = await deleteMedia(path, user?.accessToken);
      if (response) {
      }
    } else {
      if (target.files && target.files.length > 0) {
        setUploading((prevUploading: any) => {
          return { ...prevUploading, [target.name]: true };
        });
        setProcessing(true);
        const url = await uploadFile(target.files[0], user?.accessToken);
        setUploading((prevUploading: any) => {
          return { ...prevUploading, [target.name]: false };
        });
        setProcessing(false);
        setDoneUrl({ name: target.name, url });
        const path = deleteRequest(moduleName, target.name);
        const response: any = await deleteMedia(path, user?.accessToken);
        if (response) {
        }
      } else {
        setData({ ...data, [target.name]: target.value });
      }
    }
  };

  //handling upload completiong
  useEffect(() => {
    if (doneUrl.name && doneUrl.url) {
      //need to check that the done url was the dependency caused the trigger
      //data is a dependency to keep tracking only of it's value
      if (doneUrl === prevDoneUrl.current) return;
      prevDoneUrl.current = doneUrl;
      if (doneUrl.name.includes("mediaF")) {
        const isPure = doneUrl.name.includes("pure");
        if (data.mediaFiles) {
          setData((data: any) => {
            const dataClone = [...data.mediaFiles];
            // eslint-disable-next-line eqeqeq
            const searching = dataClone.find((m: any) => m.id == doneUrl.key);
            var returnData;

            if (searching) {
              if (isPure) searching.musicFreeUrl = doneUrl.url;
              else searching.withMusicUrl = doneUrl.url;
              returnData = { ...data, mediaFiles: dataClone };
            } else {
              const newMedia = {
                id: doneUrl.key,
                name: "",
                musicFreeUrl: isPure ? doneUrl.url : "",
                withMusicUrl: !isPure ? doneUrl.url : "",
              };
              returnData = {
                ...data,
                mediaFiles: [...data.mediaFiles, newMedia],
              };
            }
            return returnData;
          });
        } else {
          setData((data: any) => {
            // if isPure then update create mediaFile object with musicFreeUrl
            return {
              ...data,
              mediaFiles: [
                {
                  id: doneUrl.key,
                  name: "",
                  musicFreeUrl: isPure ? doneUrl.url : "",
                  withMusicUrl: !isPure ? doneUrl.url : "",
                },
              ],
            };
          });
        }
      } else {
        setData({ ...data, [doneUrl.name]: doneUrl.url });
      }
    }
  }, [doneUrl, data, locale]);

  //function for handling changes for inputs of type checkbox
  const handleBooleanChange = (e: FormEvent) => {
    const target = e.target as HTMLInputElement;
    setData({ ...data, [target.name]: target.checked });
  };

  //function to handle input of type add (submit)
  const handleAddSubmit = (name: string) => {
    if (name === "symbol" || name === "place") {
      if (!temp.symbol || !temp.place) return;
      const currentData = data.translations?.find(
        (t: any) => t.locale === locale
      ).article
        ? data.translations?.find((t: any) => t.locale === locale).article
        : [];
      let val = data["article"]
        ? [...data["article"], { symbol: temp["symbol"], place: temp["place"] }]
        : [...currentData, { symbol: temp["symbol"], place: temp["place"] }];
      setData({ ...data, article: val });
      setTemp({ ...temp, place: "", symbol: "" });
      //Now it's added
      setTAdded({ ...TAdded, article: true });
    } else if (name === "article") {
      const currentData = data.translations?.find(
        (t: any) => t.locale === locale
      )[name]
        ? data.translations?.find((t: any) => t.locale === locale).article
        : [];
      const newValues = data[name]
        ? [...data[name], temp[name]]
        : [...currentData, temp[name]];
      setData({ ...data, [name]: newValues });
      setTemp({ ...temp, [name]: "" });
      setTAdded({ ...TAdded, val: true });
    } else if (name === "affirmation") {
      const translation = data.translations?.find(
        (t: any) => t.locale === locale
      )
        ? data.translations?.find((t: any) => t.locale === locale)[name]
        : [];
      const newValues = data["affirmation"]
        ? [...data["affirmation"], temp["affirmation"]]
        : [...translation, temp["affirmation"]];
      setData({ ...data, affirmation: newValues });
      setTemp({ ...temp, affirmation: "" });
      setTAdded({ ...TAdded, val: true });
    } else {
      const translation = data.translations?.find(
        (t: any) => t.locale === locale
      )
        ? data.translations?.find((t: any) => t.locale === locale)[name]
        : [];
      const newValues = data[name]
        ? [...data[name], temp[name]]
        : [...translation, temp[name]];
      setData({ ...data, [name]: newValues });
      setTemp({ ...temp, [name]: "" });
      setTAdded({ ...TAdded, val: true });
    }
    setTimeout(() => {
      let currentArticleList: HTMLDivElement | any = articlesList.current;
      currentArticleList.scrollTo(0, currentArticleList.scrollHeight);
      // articlesList.current!.scrollTop = articlesList.current!.scrollHeight!
    }, 0);
  };

  //function to handle input of type add (change)
  const handleAddChange = (e: FormEvent) => {
    const target = e.target as HTMLInputElement;
    setTemp({ ...temp, [target.name]: target.value });
  };

  //function to remove from input of type add
  const handleAddFilter = (name: string, key: string) => {
    if (moduleName === "healing") {
      let translations: any = [];
      if (data.translations) {
        translations = [...data.translations];
        //remove from translations
        const translation = translations.find((t: any) => t.locale === locale);
        if (translation) {
          translation["affirmations"] = translation["affirmations"].filter(
            (val: string) => val !== name
          );
          const newAffirmations = data.affirmations
            ? [...data.affirmations].filter((val: string) => val !== name)
            : translation.affirmations.filter((val: string) => val !== name);
          setData({ ...data, affirmations: newAffirmations, locale });
          setTAdded({ ...TAdded, val: true });
          // setTemp([...newAffirmations]);
          return;
        }
      }
    }
    var translations: any = [];
    if (data.translations) {
      translations = [...data.translations];
      //remove from translations
      const translation = translations.find((t: any) => t.locale === locale);
      if (translation) {
        translation[key] = translation[key].filter(
          (val: string) => val !== name
        );
      }
    }
    const newValues = data[key]?.filter((val: string) => val !== name);
    setData({ ...data, [key]: newValues, translations });
  };

  //function to remove media
  const handleMediaFilter = (id: number, key: string) => {
    var translations: any = [];
    if (data.translations) {
      translations = [...data.translations];
      //remove from translations
      const translation = translations.find((t: any) => t.locale === locale);
      if (translation) {
        translation[key] = translation[key].filter((val: any) => val.id !== id);
      }
    }
    const newValues = data[key]?.filter((val: any) => val.id !== id);
    if (newValues) {
      setData({ ...data, [key]: newValues, translations });
    } else {
      setData({ ...data, translations });
    }
  };

  //function to reset media for a field
  const resetMedia = async (field: string) => {
    let type: string = "";
    if (field.includes("image")) {
      type = "image";
    }
    if (field.includes("video")) {
      type = "video";
    }
    const request = mapRequest(moduleName, "", type);
    const response: any = await deleteMedia(request.path, user?.accessToken);
    if (response) {
    }
    const endPoint = putEndpoint.includes("{id}")
      ? putEndpoint.replace("{id}", `${item.id}`)
      : putEndpoint.replace("{slug}", `${item.slug}`);
    const responseAPI = await post(
      endPoint,
      request.body,
      locale,
      request.method.toUpperCase(),
      user?.accessToken
    );
    if (responseAPI) {
      refetchRecords();
      toast({
        title: "Removed Media Successfully..",
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  //A map that joins each type with it's suitable handler
  const handlersMap = {
    text: handleTextChange,
    file: handleMediaChange,
    date: handleTextChange,
    checkbox: handleBooleanChange,
    textArea: handleTextChange,
  };
  const videoTypesList = ["videoUrl", "videoTFT", "url"];
  //function that returns the suitable component
  const getInputComponent = (
    name: string,
    type: keyof {
      date: (props: CInput) => any;
      file: (props: CInputFile) => any;
      checkbox: (props: CInputCheckbox) => any;
      text: (props: CInput) => any;
      textArea: (props: CTextarea) => any;
    } &
      keyof {
        date: (e: React.FormEvent) => void;
        file: (e: React.FormEvent) => Promise<void>;
        checkbox: (e: React.FormEvent) => void;
        text: (e: React.FormEvent) => void;
        textArea: (e: React.FormEvent) => void;
      },
    required?: boolean,
    multiple?: boolean | undefined,
    disabled?: boolean | undefined
  ) => {
    const InputComponent = compMap[type];
    var defaultValue: any = "";

    //check that name is equal to one of the keys on the object
    const keyName = name as keyof CMSModel & keyof CMSModel["translations"];
    defaultValue = item[keyName];
    if (!defaultValue && item.translations) {
      //Get the correct translations
      const translations = item.translations?.find(
        (translation) => translation.locale === locale
      );
      if (translations && typeof translations[keyName] === "string")
        defaultValue = translations[keyName];
    }
    //Default values coming from media object
    if (!defaultValue && item.media) {
      const val = item.media.find(
        (m: any) => m.type?.toLowerCase() === labelMap[name]?.toLowerCase()
      );
      if (val && val.url) {
        defaultValue = val.url;
      }
    }
    if (type !== "file") {
      return (
        <InputComponent
          id={name}
          name={name}
          onChange={handlersMap[type]}
          type={type}
          style={{
            position: "relative",
            height: type === "textArea" ? "250px" : "initial",
          }}
          defaultValue={defaultValue}
          defaultChecked={defaultValue}
          required={required}
          disabled={disabled}
        />
      );
    } else {
      return (
        <React.Fragment>
          {name !== "mediaFiles" && name !== "media" && (
            <h6 className="h6">
              Current: <a href={defaultValue}>{defaultValue}</a>
            </h6>
          )}
          {(videoTypesList.includes(name) ||
            moduleName === "training" ||
            (moduleName === "learning" && item.type === "video") ||
            mediaType === "video" ||
            mediaType === "audio") && (
            <div onChange={handleChange} style={{ marginBlock: "15px" }}>
              {/* <h6>Current: <a href={defaultValue || data.media?.find((m: any) => m.type === 'video')?.url}>{defaultValue || data.media?.find((m: any) => m.type === 'video')?.url}</a></h6> */}
              <CInput
                id={name}
                type="radio"
                value="upload"
                name="video"
                style={{ width: "20px", height: "auto", display: "inline" }}
                defaultChecked
                disabled={uploading[name]}
              />
              Upload
              <CInput
                id={name}
                type="radio"
                value="browse"
                name="video"
                style={{ width: "20px", height: "auto", display: "inline" }}
                disabled={uploading[name]}
              />
              External Link
            </div>
          )}
          {videoType === "browse" &&
          (videoTypesList.includes(name) ||
            moduleName === "training" ||
            (moduleName === "learning" && item.type === "video") ||
            mediaType === "video") ? (
            <div>
              <h6>Url:</h6>
              <CInput
                id={name}
                name={name}
                onChange={handleMediaChange}
                required={required}
                multiple={multiple}
                placeholder="YouTube Url"
              ></CInput>
            </div>
          ) : name === "mediaFiles" ? (
            <>
              <ul style={{ padding: "0 30px" }}>
                {item?.translations
                  ?.find((trans: any) => trans.locale === locale)
                  ?.mediaFiles?.map((mediaFile: any) => (
                    // <li className="mt-2" key={mediaFile.id}>
                    //   <a
                    //     href={mediaFile.url}
                    //     target="_blank"
                    //     rel={"noreferrer"}
                    //   >
                    //     {mediaFile.name}
                    //   </a>
                    //   <IconButton
                    //     size="sm"
                    //     variant="ghost"
                    //     margin="0 15px"
                    //     onClick={() =>
                    //       handleMediaFilter(mediaFile.id, "mediaFiles")
                    //     }
                    //     aria-label="Remove"
                    //     icon={<FaTimes />}
                    //   />
                    // </li>
                    <></>
                  ))}
              </ul>
              <div className="mt-3">
                {data.mediaFiles?.map((item: any, index: number) => (
                  <div className="mt-3">
                    <CLabel>Name</CLabel>
                    <Box display="flex">
                      <CInput
                        className="op-add-input"
                        key={`mediaF-name-loc-${item.id}`}
                        id={`mediaF-name-${item.id}`}
                        name={`mediaF-name-${item.id}`}
                        type="text"
                        onChange={(e) => {
                          //console.log(data);
                          handleTextChange(e);
                        }}
                        data-kop={item.id}
                        disabled={uploading[`mediaF-${item.id}`]}
                        value={item.name}
                        style={{
                          width: "400px",
                        }}
                      />
                      <CButton
                        color="danger"
                        size="md"
                        style={{
                          marginLeft: "10px",
                        }}
                        onClick={() => {
                          //delete item from data.mediaFiles
                          setData((oldData: any) => {
                            const newData = oldData.mediaFiles.filter(
                              (m: any) => m.id !== item.id
                            );
                            return { ...oldData, mediaFiles: newData };
                          });
                        }}
                      >
                        delete audio
                      </CButton>
                    </Box>

                    <div className="mt-2">
                      <CLabel>With Music</CLabel>
                      <br />
                      <div
                        //htmlFor={`mediaF-loc-${index}-music`}
                        style={{
                          color: "#4BB543",
                        }}
                      >
                        {item.withMusicUrl ? (
                          <>
                            Current :{" "}
                            <a
                              target="_blank"
                              href={item.withMusicUrl}
                              rel="noreferrer"
                            >
                              {item.withMusicUrl}
                            </a>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                      <Box display="flex">
                        <InputComponent
                          id={`mediaF-loc-${item.id}-music`}
                          name={`mediaF-${item.id}-music`}
                          onChange={handlersMap[type]}
                          type={type}
                          style={{ position: "relative", width: "unset" }}
                          data-kop={item.id}
                          disabled={uploading[`mediaF-${item.id}-music`]}
                        />
                        <CButton
                          color="danger"
                          size="md"
                          style={{
                            marginLeft: "10px",
                          }}
                          onClick={() => {
                            //search for item in data.mediaFiles and clear withMusicUrl
                            setData((oldData: any) => {
                              const newData = oldData.mediaFiles.map((m: any) =>
                                m.id === item.id
                                  ? { ...m, withMusicUrl: "" }
                                  : m
                              );
                              return { ...oldData, mediaFiles: newData };
                            });
                          }}
                        >
                          clear
                        </CButton>
                      </Box>
                    </div>
                    <div className="mt-2">
                      <CLabel>Pure Tune</CLabel>
                      <br />
                      <div
                        //htmlFor={`mediaF-loc-${index}-music`}
                        style={{
                          color: "#4BB543",
                        }}
                      >
                        {item.musicFreeUrl ? (
                          <>
                            Current :{" "}
                            <a
                              target="_blank"
                              href={item.musicFreeUrl}
                              rel="noreferrer"
                            >
                              {item.musicFreeUrl}
                            </a>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                      <Box display="flex">
                        <InputComponent
                          id={`mediaF-loc-${item.id}-pure`}
                          name={`mediaF-${item.id}-pure`}
                          onChange={handlersMap[type]}
                          type={type}
                          style={{ position: "relative", width: "unset" }}
                          data-kop={item.id}
                          disabled={uploading[`mediaF-${item.id}-pure`]}
                        />
                        <CButton
                          color="danger"
                          size="md"
                          style={{
                            marginLeft: "10px",
                          }}
                          onClick={() => {
                            //search for item in data.mediaFiles and clear musicFreeUrl
                            setData((oldData: any) => {
                              const newData = oldData.mediaFiles.map((m: any) =>
                                m.id === item.id
                                  ? { ...m, musicFreeUrl: "" }
                                  : m
                              );
                              return { ...oldData, mediaFiles: newData };
                            });
                          }}
                        >
                          clear
                        </CButton>
                      </Box>
                    </div>
                  </div>
                ))}
                <CButton
                  color="success"
                  size="md"
                  className="mt-3"
                  onClick={() => {
                    setData((oldData: any) => {
                      //check if oldData.mediaFiles is an array
                      //use random 5 digit number as id
                      if (!oldData.mediaFiles) {
                        return {
                          ...oldData,
                          mediaFiles: [
                            {
                              id: Math.floor(Math.random() * 100000),
                              name: "",
                              musicFreeUrl: "",
                              withMusicUrl: "",
                            },
                          ],
                        };
                      }
                      const newMediaFiles = [
                        ...oldData.mediaFiles,
                        {
                          id: Math.floor(Math.random() * 100000),
                          name: "",
                          musicFreeUrl: "",
                          withMusicUrl: "",
                        },
                      ];
                      return { ...oldData, mediaFiles: newMediaFiles };
                    });
                  }}
                  disabled={!uploaded}
                  style={{ cursor: !uploaded ? "not-allowed" : "pointer" }}
                >
                  Add File
                </CButton>
              </div>
            </>
          ) : (
            <>
              <InputComponent
                id={name}
                name={name}
                onChange={handlersMap[type]}
                type={type}
                style={{ position: "relative" }}
                multiple={multiple}
                disabled={uploading[name] || disabled}
                required={!defaultValue}
              />
              <Text color="tomato">
                Warning: Once you select a media file it will update the
                existing one, So please save to avoid unexpected behavior.
              </Text>
              <div className="mt-3">
                <CButton color="danger" onClick={() => resetMedia(name)}>
                  Remove Media
                </CButton>
              </div>
            </>
          )}
          {uploading[name] && (
            <h6 style={{ marginTop: "10px", padding: "0 10px" }}>
              Uploading...
            </h6>
          )}
        </React.Fragment>
      );
    }
  };

  //function to handle submitting the data
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const activeAdd = document.activeElement?.className
      .split(" ")
      .includes("op-add-input");
    const translation = data?.translations?.find(
      (translation: any) => translation.locale === locale
    );
    if (
      (moduleName === "healing" &&
        !data["affirmations"] &&
        translation.affirmations.length === 0) ||
      data?.affirmations?.length === 0
    ) {
      toast({
        title: "Please add affirmations!",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
      // setVisible(false);
      setProcessing(false);
      return;
    }
    if (processing || activeAdd) return;
    setProcessing(true);
    //replace the id placeholder in the endpoint with the current record id
    const endPoint = putEndpoint.includes("{id}")
      ? putEndpoint.replace("{id}", `${item.id}`)
      : putEndpoint.replace("{slug}", `${item.slug}`);
    var sendData = data;
    const translations = Array.isArray(data.translations)
      ? [...data.translations]
      : undefined;
    if (translations) {
      const translation = translations.find(
        (translation: any) => translation.locale === locale
      );
      if (translation) {
        var mediaFiles: any[] = [];
        if (translation?.mediaFiles) {
          if (moduleName === "wave") {
            mediaFiles = data.mediaFiles;
          } else {
            mediaFiles = data.mediaFiles
              ? [...translation.mediaFiles, ...data.mediaFiles]
              : translation.mediaFiles;
          }
          delete translation.mediaFiles;
        }
        sendData = { ...translation, ...data, mediaFiles };
      }
    }
    if (item.name === "tap") {
      if (
        (!sendData.imageTFT || !sendData.videoTFT) &&
        (!sendData.media.find((m: any) => m.type === "video") ||
          !sendData.media.find((m: any) => m.type === "image"))
      ) {
        toast({
          title: "Couldn't update the record! Kindly add video and image",
          status: "error",
          duration: 4000,
          isClosable: true,
        });
        setVisible(false);
        setProcessing(false);
        return;
      }
    }
    if (sendData.article) {
      //we need to find the other locale translation object if any so that it's not lost
      const otherTranslation = data.translations?.find(
        (t: any) => t.locale !== locale
      );
      const currentTranslation = { article: sendData.article, locale };
      if (otherTranslation) {
        sendData.translations = [otherTranslation, currentTranslation];
      } else {
        sendData.translations = [currentTranslation];
      }
    }
    //Behavior is kinda different for TFT modules, so we handle it accordingly
    if (sendData.imageTFT || sendData.videoTFT) {
      //we need to find media of type video
      const videoMedia = sendData.videoTFT
        ? { url: sendData.videoTFT, type: "video" }
        : data.media.find((m: any) => m.type === "video");
      //we need to find media of type image
      const imageMedia = sendData.imageTFT
        ? { url: sendData.imageTFT, type: "image" }
        : data.media.find((m: any) => m.type === "image");
      const sendMedia = [];
      if (videoMedia) sendMedia.push(videoMedia);
      if (imageMedia) sendMedia.push(imageMedia);
      sendData = { ...sendData, media: sendMedia };
    }
    if (moduleName === "chakra") {
      post(
        endPoint,
        { ...sendData, locale: locale },
        locale,
        "PUT",
        user?.accessToken
      );
    }
    // console.log(moduleName)
    if (moduleName === "subliminal" || moduleName === "training") {
      post(
        endPoint,
        { ...sendData, locale: locale, html: content, text: text },
        locale,
        putMethod,
        user?.accessToken
      ).then((res: any) => {
        if (res?.id || Array.isArray(res)) {
          toast({
            title: "Successfully updated the record!",
            status: "success",
            duration: 4000,
            isClosable: true,
          });
          setVisible(false);
          refetchRecords();
          setProcessing(false);
          document
            .querySelectorAll('input[type="file"]')
            .forEach((input: any) => (input.value = null));
          document
            .querySelectorAll(".op-add-input")
            .forEach((input: any) => (input.value = null));
          setMediaFs(1);
          return;
        } else {
          toast({
            title: "Couldn't update the record..",
            status: "error",
            duration: 4000,
            isClosable: true,
          });
          setVisible(false);
          setProcessing(false);
          return;
        }
      });

      return;
    }
    if (moduleName === "wave") {
      post(
        endPoint,
        { ...sendData, locale: locale, html: content, text: text },
        locale,
        putMethod,
        user?.accessToken
      ).then((res: any) => {
        if (res?.id || Array.isArray(res)) {
          toast({
            title: "Successfully updated the record!",
            status: "success",
            duration: 4000,
            isClosable: true,
          });
          setVisible(false);
          refetchRecords();
          setProcessing(false);
          document
            .querySelectorAll('input[type="file"]')
            .forEach((input: any) => (input.value = null));
          document
            .querySelectorAll(".op-add-input")
            .forEach((input: any) => (input.value = null));
          setMediaFs(1);
          return;
        } else {
          toast({
            title: "Couldn't update the record..",
            status: "error",
            duration: 4000,
            isClosable: true,
          });
          setVisible(false);
          setProcessing(false);
          return;
        }
      });

      return;
    }
    if (moduleName === "pure-tone" || moduleName === "other-source") {
      post(
        endPoint,
        { ...sendData, locale: locale, description: content },
        locale,
        putMethod,
        user?.accessToken
      ).then((res: any) => {
        if (res?.id || Array.isArray(res)) {
          toast({
            title: "Successfully updated the record!",
            status: "success",
            duration: 4000,
            isClosable: true,
          });
          setVisible(false);
          refetchRecords();
          setProcessing(false);
          document
            .querySelectorAll('input[type="file"]')
            .forEach((input: any) => (input.value = null));
          document
            .querySelectorAll(".op-add-input")
            .forEach((input: any) => (input.value = null));
          setMediaFs(1);
          return;
        } else {
          toast({
            title: "Couldn't update the record..",
            status: "error",
            duration: 4000,
            isClosable: true,
          });
          setVisible(false);
          setProcessing(false);
          return;
        }
      });

      return;
    }
    post(
      endPoint,
      { ...sendData, locale: locale },
      locale,
      putMethod,
      user?.accessToken
    ).then((res: any) => {
      if (res?.id || Array.isArray(res) || res?.message === "done") {
        toast({
          title: "Successfully updated the record!",
          status: "success",
          duration: 4000,
          isClosable: true,
        });
        setVisible(false);
        refetchRecords();
        setProcessing(false);
        document
          .querySelectorAll('input[type="file"]')
          .forEach((input: any) => (input.value = null));
        document
          .querySelectorAll(".op-add-input")
          .forEach((input: any) => (input.value = null));
        setMediaFs(1);
      } else {
        toast({
          title: "Couldn't update the record..",
          status: "error",
          duration: 4000,
          isClosable: true,
        });
        setVisible(false);
        setProcessing(false);
      }
    });
  };
  const mapRequest = (moduleName: string, bodyValue: string, type: string) => {
    let request: { method: string; body: {}; path: any } = {
      method: "",
      body: {},
      path: "",
    };
    switch (moduleName) {
      case "training":
        request.body = { url: bodyValue };
        request.path = item.translations
          ?.find((trans) => trans.url)
          ?.url?.split("/")[4];
        request.method = "post";
        break;
      case "learning":
        request.body = { url: bodyValue };
        request.path = item.translations
          ?.find((trans) => trans.url)
          ?.url?.split("/")[4];
        request.method = "post";
        break;
      case "tft":
        let newItem: CMSModel = {};
        if (type === "image") {
          newItem = item;
          //newItem?.media[0].url = bodyValue
        }
        request.body = {
          media: newItem.media,
          translations: item.translations,
        };
        request.method = "put";
        item.media?.forEach((element) => {
          if (element.type === type) {
            request.path = element.url.split("/")[4];
          }
        });
        break;
      case "chakra":
        if (type === "video") {
          request.body = { videoUrl: bodyValue };
          request.path = item.videoUrl?.split("/")[4];
          request.method = "put";
        }
        if (type === "image") {
          request.body = {
            name: item.name,
            imageUrl: bodyValue,
            locale: item.locale,
          };
          request.path = item.translations
            ?.find((trans) => trans.imageUrl)
            ?.imageUrl?.split("/")[4];
          request.method = "post";
        }
        break;
      case "solfeggio":
        request.body = { frequency: 0, videoUrl: bodyValue };
        request.path = item.videoUrl?.split("/")[4];
        request.method = "put";
        break;
      case "balance":
        request.body = { url: bodyValue };
        request.path = item.url?.split("/")[4];
        request.method = "post";
        break;
      case "manifestation":
        request.body = { url: bodyValue };
        request.path = item.url?.split("/")[4];
        request.method = "post";
        break;
      case "wave":
        if (type === "image") {
          request.body = {
            locale: item.locale,
            name: item.name,
            benefits: item.benefits,
            guides: item.guides,
            imageUrl: bodyValue,
            mediaFiles: item.mediaFiles,
            text: text,
            html: content,
          };
          request.method = "post";
          request.path = item.translations
            ?.find((trans) => trans.imageUrl)
            ?.imageUrl?.split("/")[4];
        }
        if (type === "mediaFiles") {
          request.body = {};
          request.method = "";
        }
        break;
    }
    return request;
  };

  const deleteRequest = (moduleName: string, field: string) => {
    let path: any = "";
    switch (moduleName) {
      case "training":
        path = item.translations
          ?.find((trans) => trans.locale === locale && trans.url)
          ?.url?.split("/")[4];
        break;
      case "learning":
        path = item.translations
          ?.find((trans) => trans.locale === locale && trans.url)
          ?.url?.split("/")[4];
        break;
      case "tft":
        if (field.includes("image")) {
          item.media?.forEach((element) => {
            if (element.type === "image") {
              path = element.url.split("/")[4];
            }
          });
        } else if (field.includes("video")) {
          item.media?.forEach((element) => {
            if (element.type === "video") {
              path = element.url.split("/")[4];
            }
          });
        }
        break;
      case "chakra":
        if (field.includes("video")) {
          path = item.videoUrl?.split("/")[4];
        }
        if (field.includes("image")) {
          path = item.translations
            ?.find((trans) => trans.locale === locale && trans.imageUrl)
            ?.imageUrl?.split("/")[4];
        }
        break;
      case "solfeggio":
        path = item.videoUrl?.split("/")[4];
        break;
      case "balance":
        path = item.url?.split("/")[4];
        break;
      case "manifestation":
        path = item.mediaUrl?.split("/")[4];
        break;
      case "wave":
        if (field.includes("imageUrl")) {
          path = item.translations
            ?.find((trans) => trans.locale === locale && trans.imageUrl)
            ?.imageUrl?.split("/")[4];
        }
        break;
    }
    return path;
  };
  return {
    temp,
    content,
    setContent,
    text,
    setText,
    moduleName,
    data,
    articlesList,
    TAdded,
    uploadBoolean,
    processing,
    visible,
    setVisible,
    handleTextChange,
    handleAddSubmit,
    handleAddChange,
    handleAddFilter,
    getInputComponent,
    handleSubmit,
  };
}

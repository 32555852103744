import React from "react";
import {
  CModal,
  CButton,
  CModalHeader,
  CModalBody,
  CModalTitle,
  CInput,
  CLabel,
  CSelect,
} from "@coreui/react";
import { CMSModel, Field } from "./CMSModels";
import RichTextEditor from "components/RichEditor";
import { FaPlusCircle, FaTimes } from "react-icons/fa";
import useModelEdit, { labelMap } from "./useModelEdit";

interface ModelEditProps {
  locale: string;
  item: CMSModel;
  inputFields: Array<Field>;
  putEndpoint: string;
  populated?: boolean;
  putMethod?: string;
  postEditEndpoint?: string;
  refetchRecords: () => void;
}

const ModelEdit: React.FC<ModelEditProps> = ({
  item,
  locale,
  inputFields,
  putEndpoint,
  putMethod,
  refetchRecords,
}) => {
  const {
    temp,
    content,
    setContent,
    text,
    setText,
    moduleName,
    data,
    articlesList,
    TAdded,
    uploadBoolean,
    processing,
    visible,
    setVisible,
    handleTextChange,
    handleAddSubmit,
    handleAddChange,
    handleAddFilter,
    getInputComponent,
    handleSubmit,
  } = useModelEdit({ item, locale, putEndpoint, refetchRecords, putMethod });
  return (
    <td>
      {!item.translations?.find(
        (trans) =>
          trans.locale === locale ||
          item.videoTFT === "" ||
          item.imageTFT === "" ||
          item.imageUrl === null ||
          item.videoUrl === null
      ) ? (
        <CButton color="danger" onClick={() => setVisible(true)}>
          Edit
        </CButton>
      ) : (
        <CButton color="success" onClick={() => setVisible(true)}>
          Edit
        </CButton>
      )}
      <CModal
        show={visible}
        onClose={() => setVisible(false)}
        style={{ padding: "20px 10px", overflowY: "scroll" }}
        size="xl"
        className="scrollable"
        closeOnBackdrop={false}
      >
        <CModalHeader closeButton>
          <CModalTitle>
            <span className="font-weight-bold text-capitalize h4">
              {item.slug ? item.slug : item.name ? item.name : item.frequency}
            </span>
          </CModalTitle>
        </CModalHeader>
        <CModalBody>
          <form className="pt-3" onSubmit={handleSubmit} key={item.id}>
            {inputFields.map((field) => {
              if (item.name === "9g" && field.key === "imageTFT") {
                return null;
              }
              return (
                <div
                  key={field.key}
                  style={
                    field.type === "checkbox"
                      ? { display: "flex", justifyContent: "space-between" }
                      : {}
                  }
                  className="my-3"
                >
                  <CLabel
                    htmlFor={field.key}
                    style={{
                      display: "block",
                      textTransform: "capitalize",
                      fontWeight: "bolder",
                    }}
                  >
                    {field.label
                      ? field.label
                      : (moduleName === "training" ||
                          moduleName === "protection") &&
                        field.key === "url"
                      ? "Video File"
                      : moduleName === "learning"
                      ? field.key
                      : labelMap[field.key] || field.key}
                  </CLabel>
                  {field.type === "add" ? (
                    <div>
                      <div
                        ref={articlesList}
                        style={{
                          maxHeight: "100px",
                          overflowY: "auto",
                          paddingBottom: "10px",
                        }}
                        className="op-add-cont px-2"
                      >
                        {data.translations.find(
                          (trans: any) => trans.locale === locale
                        ) &&
                          data.translations
                            .find((trans: any) => trans.locale === locale)
                            [field.key]?.map((val: any) => {
                              if (typeof val === "string") {
                                if (!TAdded.val) {
                                  return (
                                    <h6
                                      key={val}
                                      className="text-secondary d-flex justify-content-between align-items-center h6"
                                    >
                                      {val}
                                      <FaTimes
                                        onClick={() =>
                                          handleAddFilter(val, field.key)
                                        }
                                        cursor="pointer"
                                      />
                                    </h6>
                                  );
                                }
                              } else {
                                if (!TAdded.article) {
                                  return (
                                    <h6
                                      key={val?.symbol}
                                      className="text-secondary d-flex justify-content-between align-items-center h6"
                                    >
                                      <span
                                        key={val?.place}
                                        className="text-secondary d-flex justify-content-between align-items-center"
                                      >
                                        {val.symbol} -{val.place}
                                      </span>
                                      <span>
                                        <FaTimes
                                          onClick={() =>
                                            handleAddFilter(val, field.key)
                                          }
                                          cursor="pointer"
                                        />
                                      </span>
                                    </h6>
                                  );
                                }
                              }
                              return null;
                            })}
                        {data[field.key]?.map((val: any) =>
                          typeof val === "string" ? (
                            <h6
                              key={val}
                              className="text-secondary d-flex justify-content-between align-items-center h6"
                            >
                              {val}
                              <FaTimes
                                onClick={() => handleAddFilter(val, field.key)}
                                cursor="pointer"
                              />
                            </h6>
                          ) : (
                            <h6
                              key={val?.symbol}
                              className="text-secondary d-flex justify-content-between align-items-center h6"
                            >
                              <span
                                key={val?.place}
                                className="text-secondary d-flex justify-content-between align-items-center h6"
                              >
                                {val?.symbol} -{val?.place}
                              </span>
                              <span>
                                <FaTimes
                                  onClick={() =>
                                    handleAddFilter(val, field.key)
                                  }
                                  cursor="pointer"
                                />
                              </span>
                            </h6>
                          )
                        )}
                      </div>
                      {item.name === "Tapping Chart" ? (
                        <div className="d-flex align-items-center">
                          <CLabel style={{ marginRight: 4 }}>Symbol</CLabel>
                          <CInput
                            id="symbol"
                            name="symbol"
                            onChange={handleAddChange}
                            type="text"
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                e.preventDefault();
                                handleAddSubmit("symbol");
                                document.getElementById("place")?.focus();
                              }
                            }}
                            className="op-add-input"
                            value={temp["symbol"]}
                          />
                          <CLabel style={{ marginRight: 4, marginLeft: 4 }}>
                            Place
                          </CLabel>
                          <CInput
                            id="place"
                            name="place"
                            onChange={handleAddChange}
                            type="text"
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                e.preventDefault();
                                handleAddSubmit("place");
                                document.getElementById("symbol")!.focus();
                              }
                            }}
                            className="op-add-input"
                            value={temp["place"]}
                          />
                          <CButton
                            className="mx-2 p-0"
                            onClick={() => handleAddSubmit("symbol")}
                          >
                            <FaPlusCircle size={24} color="green" />
                          </CButton>
                        </div>
                      ) : (
                        <div className="d-flex align-items-center">
                          <CInput
                            data-add
                            id={field.key}
                            name={field.key}
                            onChange={handleAddChange}
                            type="text"
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                e.preventDefault();
                                handleAddSubmit(field.key);
                              }
                            }}
                            className="op-add-input"
                            value={temp[field.key]}
                          />
                          <CButton
                            className="mx-2 p-0"
                            onClick={() => handleAddSubmit(field.key)}
                          >
                            <FaPlusCircle size={24} color="green" />
                          </CButton>
                        </div>
                      )}
                    </div>
                  ) : field.type === "select" ? (
                    <CSelect
                      className="text-capitalize"
                      id={field.key}
                      name={field.key}
                      onChange={handleTextChange}
                      type={field.type}
                      required
                      multiple={field.multiple}
                    >
                      <optgroup className="text-capitalize" defaultValue={""}>
                        <option value={""}>
                          Please select a(n) {field.key}
                        </option>
                        {field.options?.map((opt, index) => (
                          <option value={opt} key={index}>
                            {opt}
                          </option>
                        ))}
                      </optgroup>
                    </CSelect>
                  ) : field.type === "richText" || field.key === "richText" ? (
                    <RichTextEditor
                      content={content}
                      text={text}
                      updateContent={(e: string) => {
                        setContent(e);
                      }}
                      updateText={(e: string) => {
                        setText(e);
                      }}
                    />
                  ) : (
                    getInputComponent(
                      field.key,
                      field.type === "date" ||
                        field.type === "text" ||
                        field.type === "file" ||
                        field.type === "checkbox" ||
                        field.type === "textArea"
                        ? field.type
                        : "text",
                      field.required,
                      field.multiple,
                      field.disabled
                    )
                  )}
                </div>
              );
            })}

            <div className="pt-3 text-center">
              <CButton
                color="success"
                size={"lg"}
                type={"submit"}
                disabled={uploadBoolean || processing}
                style={{
                  cursor:
                    uploadBoolean || processing ? "not-allowed" : "pointer",
                }}
              >
                Save
              </CButton>
            </div>
          </form>
        </CModalBody>
      </CModal>
    </td>
  );
};

export default ModelEdit;
